var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'route--' + _vm.view.toLowerCase(),
    _vm.isOnTop ? 'page--is-top' : 'page--is-scrolled' ],attrs:{"id":"app"}},[_c('div',{staticClass:"nav",class:[
      ("nav--" + (_vm.view.toLowerCase())),
      ( _obj = {}, _obj[("nav--" + (_vm.view.toLowerCase()) + "-expanded")] = _vm.isExpanded, _obj ),
      { 'nav--expanded': _vm.isExpanded } ]},[_c('div',{staticClass:"container"},[_c('NavigationComponent',{attrs:{"logo":_vm.logo,"items":_vm.items,"title":_vm.projectName,"padding":"0","action":_vm.redirect(['home'], { scrollTo: 0 }, this),"isOpen":_vm.isExpanded},on:{"update:isOpen":function($event){_vm.isExpanded=$event},"update:is-open":function($event){_vm.isExpanded=$event}}})],1)]),_c('div',{staticClass:"yellow-bar"}),_c('transition',{attrs:{"mode":"out-in","name":"route"}},[_c('router-view')],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }